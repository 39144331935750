.page-layout {
  background: rgba(0,155,255,0.74);
  height: 40vh;
  min-height: 330px;
  margin: 20vh auto 0;
  box-shadow: #969696 0 5px 38px;
  border-radius: 2px;
  max-width: 600px;
}
.learnlink-logo {
  width: 300px;
}
.subheading {
  margin-top: -10px;
  color: #fff;
}
.room-name {
  justify-content: center;
}
@media screen and (max-width: 375px) {
  .room-name {
    justify-content: space-around;
  }
}
h1,
h2,
h3,
p,
label,
.room-name {
  color: #fff !important;
}
/*# sourceMappingURL=src/views/Home.css.map */